import {Col, Container, Row} from "react-bootstrap";
import "./Servicios.scss";
import OwlCarousel from "react-owl-carousel";
import {UseDocumentTitle} from "../../hooks/useDocumentTitle";
import DescripcionSvg from "./images/two-text.svg";
import thumbServ1 from "./images/servicios-img-1.svg";
import thumbServ2 from "./images/servicios-img-2.svg";

const Servicios = (props) => {
    UseDocumentTitle("Servicios");
    let carouselResponsive = {
        0: {
            items: 1,
        },
        992: {
            items: 4
        },
    }
    return (
        <div id="servicios">
            <Container fluid={"true"} className={"with-bottom-header"}>
                <Container className={"header-container"}>
                    <Row>
                        <Col>
                            <h3 className={"green-heading"}>nuestros <strong>servicios</strong></h3>
                        </Col>
                    </Row>
                </Container>
                <OwlCarousel className="owl-theme" margin={0} mouseDrag={false} touchDrag={false} nav={false} dots={false} responsive={ carouselResponsive }>
                    <div className="item servicios-one-image" data-merge="2" style={{backgroundColor: "#A9A9A9"}}/>
                    <div className="item servicios-two-image" data-merge="2" style={{backgroundColor: "#C0C0C0"}}/>
                </OwlCarousel>
            </Container>

            <Container className={"main-container"}>
                <Row className={"d-none d-lg-block"}>
                    <Col className={"px-0"}>
                        <img src={DescripcionSvg} alt={"description"} className={"w-100"}/>
                    </Col>
                </Row>
                <Row className={"my-3 d-lg-none"}>
                    <Col className={"full-text"}>

                        <p>
                            <img src={thumbServ1} alt={"description"} className={"circle-image first"}/>

                            En Patasombra nos importa estar en constante contacto con las más recientes tendencias en diseño de interiores, ofreciendo una diversa selección de plantas para áreas como greenroofs y greenwalls.
                            A través de un análisis a profundidad, nos aseguramos que siempre cuentes con plantas que resalten las cualidades de tus espacios predilectos.
                        </p>

                        <p>
                            <img src={thumbServ2} alt={"description"} className={"circle-image second"}/>

                            Asimismo, entendemos que el rápido ritmo de trabajo de hoy en día no siempre permite tener el tiempo
                            o el personal para cuidar de una planta viva, por
                            lo que contamos también con una línea de plantas de ornato artificial que brindan ese elemento de vanguardia a cualquier espacio.</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Servicios;