import {Nav, Container, Navbar} from "react-bootstrap";
import { useState } from "react";
import {withRouter} from 'react-router-dom';

import "./Header.scss";

const Header = (props) => {

    let currentPath = props.location.pathname.substring(1);
    currentPath = currentPath.length ? currentPath : 'nosotros';

    const [selectedKey, setSelectedKey] = useState(currentPath);

    const onSelectNav = (selectedKey) => {
        setSelectedKey(selectedKey)
    };

    return (
        <div>
            <Navbar bg="clear" expand="lg">
                <Container>
                    <Navbar.Brand href="/">
                        <img src="/PataSombra.png" alt={"PataSombra"}/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto" onSelect={onSelectNav}
                        >
                            <Nav.Item>
                                <Nav.Link eventKey="nosotros" className={( selectedKey === 'nosotros' ? 'active' : null)} href={"/nosotros"}>Nosotros</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="que-hacemos" className={( selectedKey === 'que-hacemos' ? 'active' : null)} href={"/que-hacemos"}>Que Hacemos</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="servicios" className={( selectedKey === 'servicios' ? 'active' : null)} href={"/servicios"}>Servicios</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="contacto" className={( selectedKey === 'contacto' ? 'active' : null)} href={"/contacto"}>Contacto</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>


        </div>
    );
}

export default withRouter(Header);