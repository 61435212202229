import 'bootstrap/dist/css/bootstrap.min.css';

import './App.scss';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import Inicio from "./components/pages/Inicio";
import Contacto from "./components/pages/Contacto";
import Servicios from "./components/pages/Servicios";
import QueHacemos from "./components/pages/QueHacemos";
import Footer from "./components/UI/Footer";
import Header from "./components/UI/Header";
import AOS from 'aos';
import "aos/dist/aos.css";
import {useEffect} from "react";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function App() {
    global.GOOGLE_SITE_KEY = "6Lf6lU8cAAAAAEIRKlTJSgjxqGvJ4dQNLLUk3TTf";

    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }

            if (isScriptExist && callback) callback();
        }

        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${global.GOOGLE_SITE_KEY}`, function () {
            //console.log("...RaCaptcha Loaded...");
        });

        window.addEventListener("scroll", () => {
            AOS.init({
                once: true,
                duration: 700
            });
            AOS.refresh();
        });
    }, []);

    return (
        <Router>
            <Header/>
            <Switch>
                <Route exact path="/nosotros" component={Inicio}/>
                <Route exact path="/servicios" component={Servicios}/>
                <Route exact path="/que-hacemos" component={QueHacemos}/>
                <Route exact path="/contacto" component={Contacto}/>
                <Route exact path="/" component={Inicio}/>
            </Switch>
            <Footer/>
        </Router>
    );
}

export default App;
