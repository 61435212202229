import { useEffect, useState } from "react";

const UseDocumentTitle = title => {
    const [document_title, setDocumentTitle] = useState(process.env.REACT_APP_TITLE+' | '+title);

    useEffect(() => {
        // console.log("Document title: "+document_title);
        document.title = document_title;
        document.head.innerHTML += '<meta property="og:title" content="'+document_title+'"/>';
    },[document_title]);

    return [document_title, setDocumentTitle];
};

export {UseDocumentTitle};