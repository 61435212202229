import {Button} from "@material-ui/core";
import {Form, Alert} from "react-bootstrap";
import CustomTextField from "./CustomTextField";

import {makeStyles, withStyles} from '@material-ui/core/styles';
import {useState, useReducer} from "react";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
    root: {

        '& > *': {
            margin: theme.spacing(1)
        },

        "& .Mui-focused": {
            color: "#768738"
        },
        '& .MuiInputLabel-formControl': {
            color: '#768738', // Text color
            fontFamily: "'Avenir Next Condensed', 'Avenir', SansSerif;",
            fontSize: '22px',
            width: '100%'
        },

        '& .MuiInput-underline:before': {
            borderBottomColor: '#768738', // Semi-transparent underline
        },
        '& .MuiInput-underline:hover:before': {
            borderBottomColor: '#42693C', // Solid underline on hover
        },

        '& .MuiInput-underline:after': {
            borderBottomColor: '#768738', // Solid underline on focus
        },
        '& .Mui-error': {
            color: '#f44336!important', // Text color

            borderBottomColor: '#f44336', // Solid underline on hover
        },
        '& .MuiInput-formControl': {
            fontFamily: "'Avenir Next Condensed', 'Avenir', SansSerif;",
            fontSize: 18,
            weight: 500
        }
    },
}));


const ContactForm = () => {
    const fieldAlias = [
        ps_nombre => 'Nombre',
        ps_correo => 'Correo',
        ps_comentario => 'Comentario'
    ];

    const [formInput, setFormInput] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
            ps_nombre: "",
            ps_correo: "",
            ps_comentario: ""

        }
    );


    const [isSubmitting, setSubmit] = useState();
    const [isSent, setSent] = useState();
    const [errors, setErrors] = useState(
        (state, newState) => ({...state, ...newState}),
        {
            ps_nombre: "",
            ps_correo: "",
            ps_comentario: ""

        }
    );

    const classes = useStyles();

    const ColorButton = withStyles((theme) => ({
        root: {
            color: '#FFFFFF',
            backgroundColor: '#758638',
            '&:hover': {
                backgroundColor: '#42693C',
            },
            borderRadius: 0
        },
    }))(Button);

    const handleInput = (evt) => {
        const name = evt.target.name;
        const newValue = evt.target.value;

       setFormInput({[name]: newValue});
    };

    const handleFocus = () => {
        setSent(null);
        setErrors({
            ps_nombre: "",
            ps_correo: "",
            ps_comentario: ""
        });
    }


    const validateFormInput = ( ) => {
        return new Promise((resolve, reject) => {
            Object.keys(formInput).forEach((key) => {
                if(!(formInput[key]).trim().length){
                    setErrors({[key]: 'El campo es requerido'});
                    resolve(false);
                }
            });
            resolve(true);
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmit(true);

        validateFormInput().then(function(result){

            if(!result){
                setSubmit(false);
                console.log('buuh');

                console.log(errors);

                // setErrors({
                //     'ps_nombre': `Falló`
                // });
                return;
            }
            const inputs = formInput;

            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(global.GOOGLE_SITE_KEY, {action: 'submit'}).then(token => {
                    axios.post(`/api/contact`, {
                        "name": inputs.ps_nombre,
                        "email": inputs.ps_correo,
                        "comment": inputs.ps_comentario,
                        "title": "Contacto",
                        "g_recaptcha_response": token
                    }).then(res => {
                        // Send post and clean if successful
                        Array.from(document.querySelectorAll("input, textarea, phone")).forEach((input) => {
                                if (input.type !== "submit") {
                                    input.value = "";
                                }
                            }
                        );

                        setSent(true);
                        setSubmit(false);
                        console.log(res.data);
                    }).catch(err => {
                        setSent(false);
                        setSubmit(false);
                        console.log(err)
                    });

                });
            });
        });
    }

    return (<Form className={classes.root} autoComplete="off" onSubmit={handleSubmit}>
        <h3>Contacto</h3>
        <Form.Group className="mb-3 w-100">
            <CustomTextField label={"Nombre:"} type={"text"} name={"ps_nombre"} onChange={handleInput} errors={errors}  onFocus={handleFocus}/>
        </Form.Group>
        <Form.Group className="mb-3 w-100">
            <CustomTextField label={"Correo:"} type={"email"} name={"ps_correo"} onChange={handleInput} errors={errors}  onFocus={handleFocus}/>
        </Form.Group>
        <Form.Group className="w-100">
            <CustomTextField label={"Comentario:"} rows={4} inputType={"textarea"} name={"ps_comentario"} errors={errors}
                             onChange={handleInput}  onFocus={handleFocus}/>
        </Form.Group>
        <Form.Group className={"mb-0 pb-0 mt-4"}>
            {
                (isSent === false || isSent === true) ? (<Alert variant={`${isSent === false ? 'danger' : 'success'}`}>
                    {(isSent === true ? 'El mensaje ha enviado exitosamente. Nos contactaremos lo más pronto posible.' : 'Hubo un problema al enviar su mensaje. Favor de intentar de nuevo.')}
                </Alert>) : null
            }
        </Form.Group>
        <Form.Group className="w-100 text-center">

            <ColorButton type="submit" variant="contained" color="primary" className={'w-75 mt-4'}>
                {isSubmitting === true ? 'Enviando...' : 'Enviar'}
            </ColorButton>
        </Form.Group>
    </Form>);

}

export default ContactForm;