import ReactDOM from 'react-dom';
import React, { Suspense } from "react";

import './index.css';
import App from './App';

const rootElement = document.getElementById("root");

ReactDOM.render(
    <Suspense fallback="...">
        <App />
    </Suspense>,
    rootElement
);

