import "./Footer.scss";
import {Col, Container, Row} from 'react-bootstrap';

const Footer = (props) => {
    return (
        <footer>
            <Container>
                <Row>
                    <Col className={"d-none d-md-block"} >CR&Eacute;DITOS RESPONSABLES DE LA P&Aacute;GINA</Col>
                    <Col md="4"  lg="6" className={"text-center text-md-right"}>
                        <ul>
                            <li className={"footer-border right"}/>
                            {/*<li>*/}
                            {/*    <a target="_blank"  rel="noreferrer"  className={"twitter social-network"} href="https://twitter.com" title="PataSombra en Twitter">*/}
                            {/*        <i aria-hidden="true"/>*/}
                            {/*    </a>*/}
                            {/*</li>*/}
                            <li>
                                <a target="_blank"  rel="noreferrer"  className={"facebook social-network"} href="https://www.instagram.com/pata_sombra" title="PataSombra en Facebook">
                                    <i aria-hidden="true"/>
                                </a>
                            </li>
                            <li>
                                <a target="_blank"   rel="noreferrer"  className={"instagram social-network"} href="https://www.facebook.com/patasombra/" title="PataSombra en Instagram">
                                    <i aria-hidden="true"/>
                                </a>
                            </li>
                            <li className={"footer-border left"}/>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;