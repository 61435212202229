import {Col, Container, Row} from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "./Contacto.scss";
import ContactForm from "../UI/ContactForm";
import {UseDocumentTitle} from "../../hooks/useDocumentTitle";

const Contacto = (props) => {
    UseDocumentTitle("Contacto");
    let carouselResponsive = {
        0: {
            items: 1,
            loop: true,
            mouseDrag: true,
            touchDrag: true
        },

        768: {
            items: 2,
            loop: true,
            mouseDrag: true,
            touchDrag: true
        },
        992: {
            items: 3,
            mouseDrag: false,
            touchDrag: false,
            loop: false
        },
    }

    return (
        <div id="contacto">
            <Container fluid={"true"} className={"with-bottom-header"}>
                <Container className={"header-container"}>
                    <Row>
                        <Col>
                            <h3 className={"green-heading"}>
                                <strong>Mantenimiento</strong>
                            </h3>
                        </Col>
                    </Row>
                </Container>
                <OwlCarousel className="owl-theme" margin={0} nav={false} mergeFit={true} dots={false}
                             responsive={carouselResponsive}>
                    <div className="item contacto-1-image" style={{backgroundColor: "#A9A9A9"}}/>
                    <div className="item contacto-2-image" style={{backgroundColor: "#C0C0C0"}}/>
                    <div className="item contacto-3-image" style={{backgroundColor: "#DCDCDC"}}/>
                </OwlCarousel>
            </Container>

            <Container className={"main-container"}>
                <Row>
                    <Col lg={7} className={"full-text"}>
                        <p>En Patasombra, te aseguramos que nuestra labor no termina al colocar la última pieza
                            decorativa, ya que también ofrecemos planes de mantenimiento para conservar la
                            belleza de tus nuevas plantas, así como para acentuar la buena imagen de tu negocio. <br/>
                                Al llevar a cabo visitas periódicas a tu negocio, nos aseguramos que el riego sea
                            adecuado para cada especie de planta y que sea también acorde a la temporada del
                            año. Nos importa que las hojas estén limpias, prevenimos la aparición de plagas y
                            aplicamos los nutrientes necesarios para que tu planta crezca saludable y
                            feliz.</p>
                    </Col>
                    <Col>
                        <div className={"inner-box"}>
                            <ContactForm/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Contacto;