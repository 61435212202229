import {Container, Row, Col} from "react-bootstrap";
import "./Inicio.scss";
import OwlCarousel from "react-owl-carousel";
import {UseDocumentTitle} from "../../hooks/useDocumentTitle";

const Inicio = (props) => {
    UseDocumentTitle("Nosotros");
    return (
        <div id="inicio">
            <Container fluid={"true"}>
                <Container className={"header-container"}>
                    <Row>
                        <Col>
                            <h3>La <strong>naturaleza</strong> en tu <strong>espacio</strong></h3>
                        </Col>
                    </Row>
                </Container>
                <OwlCarousel className="owl-theme" margin={0} nav={false}  mouseDrag={false} touchDrag={false} mergeFit={true} dots={false}>
                    <div className="item inicio-image" data-merge="6" style={{backgroundColor: "#A9A9A9"}}/>
                </OwlCarousel>
            </Container>
            <Container className={"main-container"}>
                <Row>
                    <Col lg={10} className={"full-text"}>
                        <p>En PataSombra nos encargamos de brindarle vida a tus espacios de interior. Con nuestra selección sensible e inteligente de plantas decorativas, buscamos mejorar la calidad del aire y resaltar la personalidad de tus espacios favoritos.
                            PataSombra se fundó con la misión de brindar plantas de
                            calidad que puedan ser sostenibles en todo tipo de espacios de interior.
                        </p>
                        <p>Clientes como Greens & Proteins, Via Office y Lulu Spa están muy contentos con nuestros servicios, puesto que nuestras soluciones a la medida superan todas las expectativas.</p>

                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Inicio;