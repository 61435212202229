import {Col, Container, Row} from "react-bootstrap";
import "./QueHacemos.scss";
import OwlCarousel from "react-owl-carousel";
import {UseDocumentTitle} from "../../hooks/useDocumentTitle";

const QueHacemos = (props) => {
    UseDocumentTitle("Que hacemos");
    return (
        <div id="que-hacemos">
            <Container fluid={"true"} className={"with-bottom-header"}>
                <Container className={"header-container"}>
                    <Row>
                        <Col>
                            <h3 className={"green-heading"}>lo que mejor <strong>hacemos</strong></h3>
                        </Col>
                    </Row>
                </Container>
                <OwlCarousel className="owl-theme" mouseDrag={false} touchDrag={false} margin={0} nav={false} mergeFit={true} dots={false}>
                    <div className="item que-hacemos-image" data-merge="6" style={{backgroundColor: "#A9A9A9"}}/>
                </OwlCarousel>
            </Container>

            <Container className={"main-container"}>
                <Row>
                    <Col lg={3} className={"d-flex align-items-center"}>
                        <div style={{backgroundColor: "#A9A9A9"}} className={"circle-image quehacemos-thumb"}></div>
                    </Col>
                    <Col lg={9} className={"full-text"}>
                        <p>A lo largo de un proceso personalizado, te acompañamos en la selección de plantas y
                            macetas para que todos tus proyectos de diseño de interiores sean un éxito.</p>

                        <p> En Patasombra, no sólo te proveemos de plantas de interior y sus respectivas
                            macetas, sino que también llevamos a cabo
                            prácticas sustentables. Una vez que una planta haya cumplido su vida útil,
                            nuestros jardineros la llevan a nuestro vivero, donde es
                            procesada para uso de composta. Asimismo, mon- itoreamos el riego y el buen uso del
                            agua en las plantas de nuestros clientes, contestando todas las dudas que tengan
                            respecto a los distintos cuidados que éstas requieran.</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default QueHacemos;