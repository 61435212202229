import TextField from "@material-ui/core/TextField";
import {useState, setState} from "react";

const CustomTextField = (props) => {
    const [textValue, setState] = useState({
        textFieldInput: ''
    });

    const handleChange = (event) => {
        event.preventDefault();
        setState({
            textFieldInput: event.target.value,
        });

        props.onChange(event);
    };

    if (props.inputType === 'textarea') {
        return (<TextField className={"w-max"} type={props.type ? props.type : 'text'} rows={4} multiline
                           key={`textfield-${props.name}`}
                           label={props.label} onChange={handleChange} onFocus={props.onFocus} name={props.name} {...(props.errors[props.name] && { error: true, helperText: props.errors[props.name]  })}/>);
    }


    return (<TextField className={"w-max"} type={props.type ? props.type : 'text'} key={`textfield-${props.name}`}
                       label={props.label} onChange={handleChange} onFocus={props.onFocus} name={props.name} {...(props.errors[props.name] && { error: true, helperText: props.errors[props.name]  })}/>);
}

export default CustomTextField;